import { useEffect, useRef } from "react";

import "./Popup.css";


function Popup({ isOpen, setOpen, popupName, children, onClose }) {



    const overlay = useRef();

    function handleClose() {
        setOpen(prevState => ({
            ...prevState,
            [popupName]: false,
        }))
        setTimeout(() => {
            if (onClose) onClose()
        }, 300);

    }

    useEffect(() => {
        function handleOverlayClose(evt) {
            if (overlay.current === evt.target) {
                handleClose();
            }
        }

        document.addEventListener("mousedown", handleOverlayClose);
        return () => {
            document.removeEventListener("mousedown", handleOverlayClose);
        };
    });



    return (
        <div
            className={`popup ${isOpen ? "popup_opened" : ""}`}
            ref={overlay}
        // onTouchStart={handleClose}
        >
            <div className={`popup__container`}>
                {children}
            </div>
        </div >
    );
}

export default Popup;
