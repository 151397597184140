import { CONTACT_EMAIL } from '../../assets/utils/constants';
import './Footer.css';
 
function Footer() {
  return (
    <footer className='footer'>
       <p className='footer__copy'>&copy;&nbsp;CDA, 2023</p>
       <a className='footer__email' href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </footer>
  );
}
 
export default Footer