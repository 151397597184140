import logo from '../../../assets/images/banner/logo.png'

import './Banner.css';

function Banner() {
    return (
        <div className='banner'>
            <h2 className='banner__title'>Converting players into profits</h2>
            <p className='banner__text'>iGaming platform designed to provide owners with tools for successful business and zero nonsense. Flexible and reliable.</p>
            <img className='banner__logo' src={logo} alt=''></img>
        </div>
    );
}

export default Banner