import { CloseIcon } from '../../../../assets/icons/icons';
import Popup from '../../../Popup/Popup';
import './FeaturePopup.css';

function FeaturePopup({ isOpen, popupName, setOpen, onClose, selected }) {
    return (
        <Popup
            isOpen={isOpen}
            popupName={popupName}
            setOpen={setOpen}
            onClose={onClose}
        >
            <div className='feature-popup'>
                <button className='feature-popup__close' type='button' onClick={() => {
                    setOpen(prevState => ({
                        ...prevState,
                        [popupName]: false,
                    }))
                    setTimeout(() => {
                        if (onClose) onClose()
                    }, 300);
                }}>
                    <CloseIcon mainClassName={'feature-popup__close-icon'} />
                </button>
                {selected ?
                    <>
                        {selected.popup?.icon({ mainClassName: 'feature-popup__icon' })}
                        <h5 className='feature-popup__title'>{selected.title}</h5>
                        <p className='feature-popup__text' dangerouslySetInnerHTML={{
                            __html: selected.popup?.text,
                        }}></p>
                    </>
                    : null}
            </div>
        </Popup>
    );
}

export default FeaturePopup