export function FirstIcon({ mainClassName }) {
    return (
        <svg
            width={321}
            height={140}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 321 140"
        >
            <g clipPath="url(#prefix__clip0_32_2988)">
                <path
                    d="M22.656 118.014a30.622 30.622 0 0026.577 15.421h41.561a30.621 30.621 0 0026.599-15.421l18.484-32.352a31.521 31.521 0 000-31.28l-18.418-32.396a30.629 30.629 0 00-26.577-15.42H49.32a30.624 30.624 0 00-26.6 15.42L4.152 54.36a31.542 31.542 0 000 31.28l18.505 32.374zm15.203-8.75l-17.5-30.623h46.898l21.875 37.186H49.233a13.124 13.124 0 01-11.396-6.453l.022-.11zm82.903-46.22a14.026 14.026 0 010 13.934l-16.843 29.465L82.416 70l21.524-36.42 16.822 29.464zM37.837 30.627a13.124 13.124 0 0111.396-6.563H89.22L67.345 61.251H20.381l17.456-30.624z"
                    fill="url(#prefix__paint0_linear_32_2988)"
                />
                <path
                    d="M250.028 56.876a28.435 28.435 0 0020.107-48.544 28.43 28.43 0 00-30.989-6.165 28.434 28.434 0 00-15.39 37.154 28.438 28.438 0 0026.272 17.554zm0-39.374a10.936 10.936 0 0110.727 13.071 10.941 10.941 0 01-8.593 8.593 10.94 10.94 0 01-11.228-4.65 10.935 10.935 0 011.36-13.81 10.937 10.937 0 017.734-3.204z"
                    fill="url(#prefix__paint1_linear_32_2988)"
                />
                <path
                    d="M210.654 77.525v44.973a17.5 17.5 0 0017.5 17.5h43.748a17.5 17.5 0 0017.499-17.5V77.525a69.986 69.986 0 0022.487-25.119 69.986 69.986 0 008.137-32.716 8.75 8.75 0 00-17.499 0 52.499 52.499 0 01-104.996 0 8.75 8.75 0 00-17.499 0 69.997 69.997 0 0030.623 57.835zm17.5 8.64a69.734 69.734 0 0043.748 0v36.333h-43.748V86.165z"
                    fill="url(#prefix__paint2_linear_32_2988)"
                />
            </g>
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_32_2988"
                    x1={70.015}
                    y1={6.565}
                    x2={70.015}
                    y2={133.435}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_32_2988"
                    x1={250.028}
                    y1={0.003}
                    x2={250.028}
                    y2={139.998}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint2_linear_32_2988"
                    x1={250.028}
                    y1={0.003}
                    x2={250.028}
                    y2={139.998}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <clipPath id="prefix__clip0_32_2988">
                    <path fill="#fff" d="M0 0h321v140H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function SecondIcon({ mainClassName }) {
    return (
        <svg
            width={140}
            height={140}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 140 140"
        >
            <path
                d="M91.497 98.437a8.75 8.75 0 008.749-8.75 8.75 8.75 0 10-17.499 0 8.75 8.75 0 008.75 8.75z"
                fill="url(#prefix__paint0_linear_32_2990)"
            />
            <path
                d="M4 113.749c0 14.48 11.768 26.248 26.249 26.248h78.747c14.481 0 26.249-11.768 26.249-26.248V65.625c0-13.737-10.609-25.024-24.062-26.14V22.84c0-12.578-10.237-22.837-22.836-22.837-1.335 0-2.669.11-3.96.35L23.032 11.18A23.003 23.003 0 004 33.864c0 .263.022.503.044.766 0 .13-.044.262-.044.393v78.747-.021zm113.746-48.124v48.124a8.747 8.747 0 01-8.75 8.749H30.249a8.747 8.747 0 01-8.75-8.749V56.176c1.772.437 3.61.7 5.535.7h81.962a8.748 8.748 0 018.75 8.75zM26.071 28.417L87.428 17.59a6.52 6.52 0 01.919-.088 5.345 5.345 0 015.337 5.338v16.536h-66.65a5.54 5.54 0 01-5.535-5.534c0-2.69 1.925-4.965 4.572-5.447v.022z"
                fill="url(#prefix__paint1_linear_32_2990)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_32_2990"
                    x1={69.623}
                    y1={0.003}
                    x2={69.623}
                    y2={139.997}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_32_2990"
                    x1={69.623}
                    y1={0.003}
                    x2={69.623}
                    y2={139.997}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ThirdIcon({ mainClassName }) {
    return (
        <svg
            width={140}
            height={140}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 140 140"
        >
            <path
                d="M98.059 2.19a37.186 37.186 0 00-28.436 13.278A37.186 37.186 0 0041.186 2.19a28.436 28.436 0 00-22.77 45.39A26.249 26.249 0 009.03 65.078l-4.922 49.37c0 .699-.109 1.399-.109 2.187a21.282 21.282 0 0021.262 21.174h88.721a21.285 21.285 0 0021.262-21.262v-2.187-2.187L130.258 65.1a26.25 26.25 0 00-9.406-17.499 28.438 28.438 0 00-22.793-45.41zM60.873 80.937v39.374H25.262a3.765 3.765 0 01-3.763-3.763 2.189 2.189 0 010-.371l1.794-17.74 1.75-17.5 1.4-14a8.75 8.75 0 018.75-7.874h25.68v21.874zm0-39.373H41.186a10.937 10.937 0 010-21.874 19.687 19.687 0 0119.687 19.686v2.188zm17.5-2.188A19.686 19.686 0 0198.058 19.69a10.939 10.939 0 0110.937 10.937 10.937 10.937 0 01-10.937 10.937H78.372v-2.188zm39.373 77.172a3.767 3.767 0 01-3.763 3.763h-35.61V59.063h25.767a8.749 8.749 0 018.75 7.853l1.421 14.021 1.794 17.5 1.641 15.552c.01.131.01.263 0 .394v2.165z"
                fill="url(#prefix__paint0_linear_32_2991)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_32_2991"
                    x1={69.623}
                    y1={2.19}
                    x2={69.623}
                    y2={137.81}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}


export function FourthIcon({ mainClassName }) {
    return (
        <svg
            width={140}
            height={140}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 140 140"
        >
            <g clipPath="url(#prefix__clip0_32_2992)">
                <path
                    d="M4.191 85.618a39.639 39.639 0 0019.993 34.365l26.074 14.83a39.543 39.543 0 0039.111 0l26.074-14.83a39.64 39.64 0 0019.993-34.365V38.633a30.619 30.619 0 00-23.011-29.684L84.929 1.906a60.81 60.81 0 00-30.23 0L27.203 8.949A30.624 30.624 0 004.19 38.633v46.985zm17.5-46.985a13.124 13.124 0 019.865-12.71l27.496-7.043a43.332 43.332 0 0121.524 0l27.496 7.044a13.126 13.126 0 019.865 12.709v46.985a22.056 22.056 0 01-11.156 19.162l-26.03 14.831a22.048 22.048 0 01-21.874 0L32.824 104.78a22.053 22.053 0 01-11.133-19.162V38.633z"
                    fill="url(#prefix__paint0_linear_32_2992)"
                />
                <path
                    d="M49.602 81.834a19.315 19.315 0 0027.299 0l20.977-20.977a8.754 8.754 0 00-12.38-12.38L64.52 69.452a1.86 1.86 0 01-2.559 0l-7.853-7.852a8.755 8.755 0 10-12.38 12.38l7.874 7.853z"
                    fill="url(#prefix__paint1_linear_32_2992)"
                />
            </g>
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_32_2992"
                    x1={69.814}
                    y1={-0.002}
                    x2={69.814}
                    y2={139.987}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_32_2992"
                    x1={69.814}
                    y1={-0.002}
                    x2={69.814}
                    y2={139.987}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <clipPath id="prefix__clip0_32_2992">
                    <path fill="#fff" d="M0 0h140v140H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}


export function FifthIcon({ mainClassName }) {
    return (
        <svg
            width={140}
            height={140}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 140 140"
        >
            <path
                d="M111.815 121.209l20.978-20.956a8.754 8.754 0 10-12.381-12.381L99.434 108.85a1.858 1.858 0 01-2.56 0l-7.83-7.853a8.754 8.754 0 10-12.382 12.381l7.853 7.831a19.272 19.272 0 0027.3 0z"
                fill="url(#prefix__paint0_linear_32_2993)"
            />
            <path
                d="M7.996 96.753a46.572 46.572 0 00-3.893 18.681v2.691A21.873 21.873 0 0025.977 140H58.79a8.75 8.75 0 000-17.5H25.977a4.376 4.376 0 01-4.374-4.375v-2.691a29.138 29.138 0 012.428-11.703 29.796 29.796 0 018.947-11.528 79.254 79.254 0 0120.015-11.528 8.75 8.75 0 001.816-15.313A26.25 26.25 0 0169.728 17.5h1.05a26.25 26.25 0 0113.868 47.797A8.75 8.75 0 0094.6 79.69a43.534 43.534 0 0018.879-37.888A43.972 43.972 0 0071.434 0h-1.706a43.75 43.75 0 00-35 70 103.385 103.385 0 00-12.535 8.378A47.14 47.14 0 007.996 96.753z"
                fill="url(#prefix__paint1_linear_32_2993)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_32_2993"
                    x1={69.73}
                    y1={0}
                    x2={69.73}
                    y2={140}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_32_2993"
                    x1={69.73}
                    y1={0}
                    x2={69.73}
                    y2={140}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}


export function FirstPopupIcon({ mainClassName }) {
    return (
        <svg
            width={240}
            height={240}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 240 240"
        >
            <path
                d="M120 97.5a48.75 48.75 0 100-97.498 48.75 48.75 0 000 97.498zm0-67.5a18.753 18.753 0 0117.323 11.575 18.755 18.755 0 01-4.065 20.433A18.748 18.748 0 01101.25 48.75 18.747 18.747 0 01120 30z"
                fill="url(#prefix__paint0_linear_64_266)"
            />
            <path
                d="M52.5 132.9V210a30 30 0 0030 30h75a30 30 0 0030-30v-77.1A119.997 119.997 0 00240 33.75a15 15 0 10-30 0 90 90 0 11-180 0 15 15 0 00-30 0 120 120 0 0052.5 99.15zm30 14.813a119.563 119.563 0 0075 0V210h-75v-62.287z"
                fill="url(#prefix__paint1_linear_64_266)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_64_266"
                    x1={120}
                    y1={0}
                    x2={120}
                    y2={240}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_64_266"
                    x1={120}
                    y1={0}
                    x2={120}
                    y2={240}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E3AC2" />
                    <stop offset={1} stopColor="#972FFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}


export function CloseIcon({ mainClassName }) {
    return (
        <svg
            width={18}
            height={18}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 18 18"
        >
            <path
                opacity={0.7}
                d="M1.28 17.996L0 16.72 16.72 0 18 1.276 1.28 17.996zm15.44 0L0 1.276 1.28 0 18 16.72l-1.28 1.276z"
                fill="#fff"
            />
        </svg>
    );
}


