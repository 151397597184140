import Banner from './Banner/Banner';
import Business from './Business/Business';
import Features from './Features/Features';
import './Main.css';
 
function Main() {
  return (
    <div className='main'>
       <Banner />
       <Features />
       <Business />
    </div>
  );
}
 
export default Main