
import Footer from '../Footer/Footer';
import Main from '../Main/Main';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className='app__content'>
        <Main />
        <Footer />
      </div>
    </div>
  );
}

export default App;
