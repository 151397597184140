import { useEffect, useState } from 'react';
import { FifthIcon, FirstIcon, FirstPopupIcon, FourthIcon, SecondIcon, ThirdIcon } from '../../../assets/icons/icons';
import FeaturePopup from './FeaturePopup/FeaturePopup';
import './Features.css';
const cards = [
    {
        icon: FirstIcon,
        popup: {
            icon: FirstPopupIcon,
            text: `<b>Sportsbook</b>

Our sportsbook offers bets on&nbsp;over 30&nbsp;different sports, including soccer, basketball, baseball, hockey, tennis, e-sports, and virtual sports. Players can get live odds and stats on&nbsp;all games, and watch live streams of&nbsp;popular games to&nbsp;follow the action in&nbsp;real time. We&nbsp;also offer welcome bonuses and other promotions.

<b>Casino Games</b>

Our casino includes over 2000 games from 20&nbsp;top game studios. Players can choose from popular slots, table games, and live dealer games. We&nbsp;have slots with bonus rounds, progressive jackpots, and more. We&nbsp;also have classic table games like blackjack, roulette, and baccarat.

We&nbsp;add new games and promotions on&nbsp;a&nbsp;regular basis, so&nbsp;players always have something new to&nbsp;try.`,
        },
        title: 'Sportsbook & Casino Games',
        text: `30+&nbsp;classic, e-sport and virtual sports with wide market coverage, live stats and livestreams support.

2000+ casino games from 20&nbsp;top game studios, and our own titles on&nbsp;the way.`,
    },
    {
        icon: SecondIcon,
        popup: {
            icon: SecondIcon,
            text: `Our payment gateway offers a&nbsp;unified API that makes it&nbsp;easy to&nbsp;integrate new payment methods, including credit cards, debit cards, PayPal, other online services and cryptocurrencies. We&nbsp;also offer easy fiat-crypto processing, so&nbsp;you can accept payments in&nbsp;both traditional and digital currencies.

Our solution is&nbsp;secure and reliable. We&nbsp;use the latest security technology, scoring and KYC procedures to&nbsp;protect your customers&rsquo; data. We&nbsp;also offer online customer support to&nbsp;help players with any questions or&nbsp;problems with the top-up process.`,
        },
        title: 'Payment Gateway',
        text: `Unified payment API with the capability of&nbsp;quick integration of&nbsp;new methods.

Easy fiat-crypto processing.`,
    },
    {
        icon: ThirdIcon,
        popup: {
            icon: ThirdIcon,
            text: `Our Loyalty engine offers a&nbsp;comprehensive set of&nbsp;tools that can be&nbsp;used to&nbsp;attract and retain users. These tools include:

<b>Welcome bonus</b>: A&nbsp;bonus that is&nbsp;awarded to&nbsp;new users when they sign up&nbsp;for your loyalty program, with dependancies on&nbsp;the traffic source and promo-codes.

<b>Freebets and freespins</b>: Bets and spins that are awarded to&nbsp;users for free, with anti-fraud mechanics.

<b>Deposit bonus</b>: A&nbsp;bonus that is&nbsp;awarded to&nbsp;users when they make a&nbsp;deposit, with different mechanics, depending on&nbsp;number of&nbsp;deposits and amount.

<b>Cashbacks</b>: A&nbsp;percentage of&nbsp;the user&rsquo;s spending that is&nbsp;returned to&nbsp;them as&nbsp;a&nbsp;bonus.

Our loyalty engine also offers a&nbsp;unified loyalty system with ranks, cashbacks and bonuses. Users can earn points for specific activities and redeem these points for rewards or&nbsp;gain special terms.`,
        },
        title: 'Loyalty Engine',
        text: `All the necessary tools for both attracting and retaining users: welcome bonus, freebets, freespins, deposit bonus, etc.

Unified loyalty system with loyalty ranks and cashbacks.`,
    },
    {
        icon: FourthIcon,
        popup: {
            icon: FourthIcon,
            text: `Our antifraud system uses a&nbsp;variety of&nbsp;methods to&nbsp;identify suspicious activities, including:

<b>Active players scoring system</b>: This system assigns a&nbsp;score to&nbsp;each player based on&nbsp;their activity. Players with a&nbsp;high score are more likely to&nbsp;be&nbsp;fraudulent, so&nbsp;the system limits their ability to&nbsp;withdraw funds or&nbsp;get free bonuses. The system takes into account factors such as&nbsp;the player&rsquo;s betting patterns, the amount of&nbsp;money they deposit, and the number of&nbsp;transactions they make.

<b>Automated user and transactions suspension</b>: This system automatically suspends users and transactions that are deemed to&nbsp;be&nbsp;fraudulent.

<b>Acquisition fraud detection</b>: This system helps you to&nbsp;identify fraudulent traffic sources that are trying to&nbsp;sell you bot traffic or&nbsp;use a&nbsp;malicious attraction patterns.`,
        },
        title: 'Antifraud System',
        text: `Control suspicious activities with active players scoring system, automated user and transactions suspension.

Acquisition fraud detection for all your traffic sources.`,
    },
    {
        icon: FifthIcon,
        popup: {
            icon: FifthIcon,
            text: `Our CRM provides you with a&nbsp;user-friendly interface for collecting and reviewing players&rsquo; data. This data can be&nbsp;used to&nbsp;create custom communications tailored to&nbsp;players segments&rsquo; interests. Set up&nbsp;automatic communications that are triggered by&nbsp;specific events, such as&nbsp;a&nbsp;player&rsquo;s birthday or&nbsp;a&nbsp;player&rsquo;s first deposit to&nbsp;gain more retention and increase LTV.

Send personalised welcome messages, targeted offers to&nbsp;inactive players, track player progress and send communications with rewards for milestones achieved, promote new games and special events.

Our automatic segmentation model uses machine learning to&nbsp;segment your users based on&nbsp;their behaviour. This means that you can create custom communications, offers and bonuses that are targeted to&nbsp;specific groups of&nbsp;users.`,
        },
        title: 'CRM & User Management',
        text: `Fully-featured back-office for configuring and delivery of&nbsp;custom communications via push-notifications, sms and email.

Automatic segmentation model based on&nbsp;user behavior.`,
    },
]

function Features() {
    const [selected, setSelected] = useState(undefined)
    const [popups, setPopups] = useState({
        feature: false,
    })

    function handleSelect(item) {
        setSelected(item)
        setPopups(prevState => ({
            ...prevState,
            feature: true,
        }))
    }

    const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
    const [popupScrollPosition, setPopupScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollWindowPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    useEffect(() => {
        if (popups.feature) {
            document.body.style.overflow = "hidden";
            document.body.style.top = `-${scrollWindowPosition}px`;
            setPopupScrollPosition(scrollWindowPosition);
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);
            //
            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
            console.log(popupScrollPosition);
            window.scrollTo(0, popupScrollPosition);
            setScrollWindowPosition(popupScrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popups]);

    return (
        <div className='features'>
            <FeaturePopup
                isOpen={popups.feature}
                popupName={'feature'}
                setOpen={setPopups}
                onClose={() => {
                    setSelected(undefined)
                }}
                selected={selected}

            />
            {cards.map((item, i) => (
                <div className={`features__card features__card_type_${i + 1}`} key={`features__card_type_${i + 1}`}>
                    {item.icon({ mainClassName: 'features__card-icon' })}
                    <h4 className='features__card-title'>{item.title}</h4>
                    <p className='features__card-text' dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}></p>
                    <button className='features__card-btn' type='button' onClick={() => {
                        handleSelect(item)
                    }}>
                        Learn more
                    </button>
                </div>
            ))}

        </div>
    );
}

export default Features