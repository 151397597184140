import games from '../../../assets/images/business/games-on-black.webp'

import './Business.css';

function Business() {
    return (
        <div className='business'>
            <div className='business__content'>
                <h3 className='business__title'>Your business, your way</h3>
                <p className='business__text'>With our cutting-edge software development approach, we&nbsp;have established a&nbsp;process for developing digital solutions that adapts to&nbsp;your business model.<br /><br />Our platform not only provides an&nbsp;attractive user interface for your players, but also the ability to&nbsp;completely restructure the back-office in&nbsp;a&nbsp;way you prefer. Bring your ideas, and we&rsquo;ll implement.</p>
                <div className='business__img-box'>
                    <img className='business__img' src={games} alt=''></img>
                </div>
            </div>
        </div>
    );
}

export default Business